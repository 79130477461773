import apiMunzen from "../../api/ApiMunzen";

let initialState = () => {
  return {
    invoiceSentInformation: [],
  };
};
///
export default {
  namespaced: true,
  state: initialState,
  getters: {
    getInvoiceSentInformation(state) {
      return state.invoiceSentInformation;
    },
  },
  mutations: {
    setInvoiceSentInformation(state, payload) {
      state.invoiceSentInformation = payload;
    },
    resetState(state) {
      // acquire initial state
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
  },
  actions: {
    fetchInvoiceSentInformation(context, payload) {
      const comprobanteId = payload.comprobanteId;
      return new Promise((resolve, reject) => {
        apiMunzen
          .get(`comprobantes/v40/${comprobanteId}/get-invoice-sent-information`)
          .then((response) => {
            const invoiceSentInfo = response.data.data;
            context.commit("setInvoiceSentInformation", invoiceSentInfo);
            resolve(invoiceSentInfo);
          })
          .catch((error) => {
            console.log(error.response);
            reject(false);
          });
      });
    },

    fetchSharingInvoiceSentInformation(context, payload) {
      const comprobanteId = payload.comprobanteId;
      return new Promise((resolve, reject) => {
        apiMunzen
          .get(`comprobantes/v40/${comprobanteId}/get-sharing-invoice-sent-information`)
          .then((response) => {
            const sharingInvoiceSentInfo = response.data.data;
            resolve(sharingInvoiceSentInfo);
          })
          .catch((error) => {
            console.log(error.response);
            reject(false);
          });
      });
    },

    downloadPdf(...payload) {
      const comprobanteId = payload[1];
      return new Promise((resolve, reject) => {
        apiMunzen
          .get(`comprobantes/v40/${comprobanteId}/get-invoice-pdf`)
          .then((response) => {
            resolve(response.data.data);
          })
          .catch((error) => {
            console.log(error.response);
            reject(false);
          });
      });
    },

    downloadXml(...payload) {
      const comprobanteId = payload[1];
      return new Promise((resolve, reject) => {
        apiMunzen
          .get(`comprobantes/v40/${comprobanteId}/get-invoice-xml`)
          .then((response) => {
            resolve(response.data.data);
          })
          .catch((error) => {
            console.log(error.response);
            reject(false);
          });
      });
    },

    createShareLink(...payload) {
      const receivedData = payload[1];
      const comprobanteId = receivedData.comprobanteId;

      const data = {
        'share_method': receivedData.shareMethod,
      }
      return new Promise((resolve, reject) => {
        apiMunzen
          .post(`comprobantes/v40/${comprobanteId}/create-share-invoice-link`, data)
          .then((response) => {
            resolve(response.data.data);
          })
          .catch((error) => {
            console.log(error.response);
            reject(false);
          });
      });
    },
    sendFacturaByEmail(...payload) {
      const data = payload[1];
      return new Promise((resolve, reject) => {
        apiMunzen
          .post(
            `/comprobantes/v40/${data.facturaId}/send-by-email`,
            {
              emails: data.emails,
              comprobante_type: data.comprobanteType,
            },
          )
          .then(() => resolve(true))
          .catch((error) => {
            reject({
              success: false,
              error: error.response,
            });
          });
      });
    },

    cancelComprobante({}, cancelData) {
      return new Promise((resolve, reject) => {
        apiMunzen
          .post("/facturacion/V40/cancelar-factura", cancelData)
          .then((resp) => {
            const cancelledInfo = resp.data.data;
            resolve(cancelledInfo);
          })
          .catch((error) => {
            reject({
              success: false,
              statusCode: error.response.status,
              apiErrorClientMessage: error.response.data.message
            });
          });
      });
    },
  },
};
