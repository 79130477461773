import Vue from "vue";
import Vuex from "vuex";
import InitialConfig from "./modules/InitialConfig";
import Auth from "./modules/Auth";
import Clients from "./modules/Clients";
import ProductsServices from "./modules/ProductsServices";
import ImpuestosTrasladados from "./modules/ImpuestosTrasladados";
import ImpuestosRetenidos from "./modules/ImpuestosRetenidos";
import SellosDigitales from "./modules/SellosDigitales";
import DatosFiscales from "./modules/DatosFiscales";
import SATCatalogs from "./modules/SATCatalogs";
import TaxesGroups from "./modules/TaxesGroups";
import FacturaIngresoForm from "./modules/FacturaIngresoForm";
import SeriesFolios from "./modules/SeriesFolios";
import FacturasIngreso from "./modules/FacturasIngreso";
import BuyCredits from "./modules/BuyCredits";
import General from "./modules/General";
import Comprobantes from "./modules/Comprobantes";
import Payments from "./modules/Payments";
import MyCreditsOrders from "./modules/MyCreditsOrders";
import SpendingCreditsHistory from "./modules/SpendingCreditsHistory";
import FacturasGlobales from "./modules/FacturasGlobales";
import FacturaGlobalForm from "./modules/FacturaGlobalForm";
import RecepcionPago from './modules/RecepcionPago';
import RecepcionPagoForm from './modules/RecepcionPagoForm';
import PPDPendingPayments from './modules/PPDPendingPayments';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {
    clearStates({ commit }) {
      commit("Clients/resetState");
      commit("ProductsServices/resetState");
      commit("Auth/resetState");
      commit("InitialConfig/resetState");
      commit("ImpuestosTrasladados/resetState");
      commit("ImpuestosRetenidos/resetState");
      commit("SellosDigitales/resetState");
      commit("DatosFiscales/resetState");
      commit("SATCatalogs/resetState");
      commit("TaxesGroups/resetState");
      commit("FacturaIngresoForm/resetState");
      commit("SeriesFolios/resetState");
      commit("FacturasIngreso/resetState");
      commit("BuyCredits/resetState");
      commit("General/resetState");
      commit("Comprobantes/resetState");
      commit("Payments/resetState");
      commit("MyCreditsOrders/resetState");
      commit("SpendingCreditsHistory/resetState");
      commit("SpendingCreditsHistory/resetState");
      commit("FacturasGlobales/resetState");
      commit("FacturaGlobalForm/resetState");
      commit("RecepcionPago/resetState");
      commit("RecepcionPagoForm/resetState");
      commit("PPDPendingPayments/resetState");
    },
  },
  getters: {},
  modules: {
    InitialConfig,
    Auth,
    Clients,
    ProductsServices,
    ImpuestosTrasladados,
    ImpuestosRetenidos,
    SellosDigitales,
    DatosFiscales,
    SATCatalogs,
    TaxesGroups,
    FacturaIngresoForm,
    SeriesFolios,
    FacturasIngreso,
    BuyCredits,
    General,
    Comprobantes,
    Payments,
    MyCreditsOrders,
    SpendingCreditsHistory,
    FacturasGlobales,
    FacturaGlobalForm,
    RecepcionPago,
    RecepcionPagoForm,
    PPDPendingPayments
  },
});

