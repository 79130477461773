import apiMunzen from "../../api/ApiMunzen";

let initialState = () => {
  return {
    pendingPayments: [],
    loading: false,
    shouldFetch: true, // Initialize as true to fetch on first load
  };
};

const state = initialState();

const getters = {
  getPendingPayments: (state) => state.pendingPayments,
  getPendingPaymentsCount: (state) => state.pendingPayments.length,
  getLoading: (state) => state.loading,
  getShouldFetch: (state) => state.shouldFetch,
};

const mutations = {
  setPendingPayments(state, payments) {
    state.pendingPayments = payments;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setShouldFetch(state, value) {
    state.shouldFetch = value;
  },
  resetState(state) {
    // acquire initial state
    const s = initialState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
};

const actions = {
  fetchPendingPayments({ commit, rootGetters, state }) {
    // If we don't need to fetch, return existing data
    if (!state.shouldFetch) {
      return Promise.resolve(state.pendingPayments);
    }

    commit('setLoading', true);
    
    return new Promise((resolve, reject) => {
      const enterprise = rootGetters['Auth/getAuthEnterprise'];
      
      apiMunzen
        .get(`facturacion/V40/${enterprise.id}/ppd-pending-payments`)
        .then((response) => {
          commit('setPendingPayments', response.data.data);
          commit('setShouldFetch', false); // Set to false after successful fetch
          resolve(response.data.data);
        })
        .catch((error) => {
          console.error('Error fetching pending payments:', error);
          reject({
            success: false,
            statusCode: error.response.status,
          });
        })
        .finally(() => {
          commit('setLoading', false);
        });
    });
  },

  // Action to mark that we need to fetch data
  markToFetchPendingPayments({ commit }) {
    commit('setShouldFetch', true);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}; 