import apiMunzen from "../../api/ApiMunzen";

let initialState = () => {
  return {
    pagos: [],
    shouldHighlightPayments: false
  };
};

export default {
  namespaced: true,
  state: initialState,
  getters: {
    isHighlighting: state => state.shouldHighlightPayments,
    getPagos: state => state.pagos,
    getTotalPagado: state => {
      return state.pagos.length
        ? state.pagos
            .filter((pago) => pago.comprobante?.status === "timbrada")
            .reduce(
              (sum, pago) => sum + pago.documento_relacionado.importe_pagado,
              0
            )
        : 0;
    },
  },

  mutations: {
    setHighlightPayments(state, value) {
      state.shouldHighlightPayments = value;
    },
     appendToPagosList(state, pago) {
      //open the created payment
      pago.opened = true;

      //close the other payments
      state.pagos.forEach(pago => {
          pago.opened = false;
      });

      state.pagos.unshift(pago);
    },
    setPagos(state, pagos) {
      state.pagos = pagos.map(pago => ({
        ...pago,
        opened: false
      }));
    },
    updatePago(state, updatedPago) {
      const index = state.pagos.findIndex(p => p.id === updatedPago.id);
      if (index !== -1) {
        state.pagos.splice(index, 1, {
          ...updatedPago,
          opened: true // Preserve the opened state
        });
      }
    },
    resetState(state) {
      // acquire initial state
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
  },

  actions: {
    async fetchPagos({ commit }, facturaId) {
      try {
        const response = await apiMunzen.get(`/facturacion/V40/complemento-pago/${facturaId}`);
        commit('setPagos', response.data.data);
      } catch (error) {
        console.error("Error fetching pagos:", error);
        throw error;
      }
    },

    // Trigger the highlight animation for the payments
    async triggerPaymentHighlight({ commit }) {
      commit('setHighlightPayments', true);
      
      return new Promise(resolve => {
        setTimeout(() => {
          commit('setHighlightPayments', false);
          resolve();
        }, 8000); // also change the animation duration in the css class highlight-section in the Pagos.vue component
      });
    }
  }
}; 