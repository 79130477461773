import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { VERSION } from "./version";
import "./assets/sass/app.scss";
import "@fortawesome/fontawesome-free/scss/fontawesome.scss";
import "@fortawesome/fontawesome-free/scss/solid.scss";
import "@fortawesome/fontawesome-free/scss/brands.scss";
import "izitoast/dist/css/iziToast.css";
import vueDebounce from "vue-debounce";
import "./filters/Currency";
import "./filters/Dates";
import * as Sentry from "@sentry/vue";
import "@stripe/stripe-js";
import "./js/momentEsLocale";
import "./js/LoadScript";

Vue.use(vueDebounce, {
  defaultTime: "900ms",
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

//######## Sección referente a configuraciones de Sentry ########

//Configuraciones generales de Sentry
Sentry.init({
  Vue,
  dsn: "https://59a842f1049349e387912f9c3923d7a5@o4504988110356480.ingest.sentry.io/4504988175892480",
  environment: process.env.NODE_ENV,
  release: `munzen@${VERSION}`,

  // This sets the sample rate to be 30%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: .2,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  // It´s recommended to set this value to 1.0 in production
  replaysOnErrorSampleRate: 1.0,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: .3,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["app.munzen.mx"],


  //Option to track components
  trackComponents: true,

  // Control which lifecycle hooks should be tracked. This is helpful if,
  // for example, you want to know if some components are removed during the initial page load,
  // in which case you can add a destroy hook to the default:
  // all available ['activate', 'create', 'destroy', 'mount', 'update']
  hooks: ["activate", "create", "destroy", "mount", "update"],

  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: true,
    }),
  ],

  //Configuración para los errores de red: Para capturar los errores de red
  beforeSend(event, hint) {
    if (process.env.NODE_ENV !== 'production') {
      return null;
    }

    if (hint && hint.originalException && hint.originalException.isAxiosError) {
      return null;
    }

    return event;
  },
});

//vm es una referencia al objeto de la instancia de Vue,
// mientras que vm.$options.name devuelve el nombre del componente de Vue que está siendo renderizado actualmente.
// En este caso, estamos agregando el nombre del componente como una información adicional del evento, lo que puede ayudar a identificar el origen del error.
Vue.config.errorHandler = function (error, vm, info) {
  Sentry.withScope(function (scope) {
    scope.setExtra("component", vm.$options.name);
    scope.setExtra("props", vm.$props);
    scope.setExtra("info", info);
    Sentry.captureException(error);
  });
};
