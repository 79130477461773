import apiMunzen from "../../api/ApiMunzen";
import { getField, updateField } from "vuex-map-fields";
import moment from "moment";
import helper from "../../js/Helper";
let initialState = () => {
  return {
    formasPagoLoaded: false,
    seriesLoaded: false,
    paymentDateMenu: false,
    paymentDate: null,
    paymentTime: null,
    serie: null,
    formData: {
      factura_id: null,
      serie_id: null,
      enterprise_id: null,
      client_id: null,
      creation_date: null,
      forma_pago: null,
      payment_date: null,
      payment_amount: null,
      num_operacion: null,
      rfc_emisor_cta_ordenante: null,
      nom_banco_ordenante_ext: null,
      cta_ordenante: null,
      rfc_emisor_cta_ben: null,
      cta_beneficiario: null,
      tipo_cadena_pago: null,
      cert_pago: null,
      cad_pago: null,
      sello_pago: null,
    },
    showOptionalFields: false,
    formaPagoRules: {
      bankFields: ["02", "03", "04", "05", "06", "28", "29"],
      beneficiarioFields: ["02", "03", "04", "05", "28", "29"],
      simpleFields: [
        "01", "08", "12", "13", "14", "15", "17", 
        "23", "24", "25", "26", "27", "30", "31"
      ]
    }
  };
};

export default {
  namespaced: true,
  state: initialState,
  getters: {
    getField,
    componentsLoaded: (state) => state.formasPagoLoaded && state.seriesLoaded,
    
    showBankFields: (state) => {
      return state.formaPagoRules.bankFields.includes(state.formData.forma_pago);
    },
    showNumOperacion: (state) => {
      return state.formData.forma_pago && (
        state.formaPagoRules.bankFields.includes(state.formData.forma_pago) ||
        state.formaPagoRules.simpleFields.includes(state.formData.forma_pago)
      );
    },

    showRfcEmisorCtaOrd: (state) => {
      return state.formaPagoRules.bankFields.includes(state.formData.forma_pago);
    },

    showNomBancoOrdExt: (state) => {
      return state.formaPagoRules.bankFields.includes(state.formData.forma_pago);
    },

    showCtaOrdenante: (state) => {
      return state.formaPagoRules.bankFields.includes(state.formData.forma_pago);
    },

    showRfcEmisorCtaBen: (state) => {
      return state.formaPagoRules.beneficiarioFields.includes(state.formData.forma_pago);
    },

    showCtaBeneficiario: (state) => {
      return state.formaPagoRules.beneficiarioFields.includes(state.formData.forma_pago);
    },

    showSPEIFields: (state) => {
      return state.formData.forma_pago === "03";
    },

    ctaOrdenanteRules: (state) => {
      console.log(state.formData.forma_pago);
      
      switch (state.formData.forma_pago) {
        case "02": // Cheque nominativo
          return { pattern: /^(\d{11}|\d{18})$/ };
        case "03": // Transferencia electrónica de fondos
          return { pattern: /^(\d{10}|\d{16}|\d{18})$/ };
        case "04": // Tarjeta de crédito
          return { pattern: /^\d{16}$/ };
        case "05": // Monedero electrónico
          return { pattern: /^(\d{10,11}|\d{15,16}|\d{18}|[A-Z0-9_]{10,50})$/ };
        case "06": // Dinero electrónico
          return { pattern: /^\d{10}$/ };
        case "28": // Tarjeta de débito
          return { pattern: /^\d{16}$/ };
        case "29": // Tarjeta de servicios
          return { pattern: /^\d{15,16}$/ };
        default:
          return "";
      }
    },

    ctaBeneficiarioRules: (state) => {
      switch (state.formData.forma_pago) {
        case "02": // Cheque nominativo
        case "04": // Tarjeta de crédito
        case "05": // Monedero electrónico
        case "28": // Tarjeta de débito
        case "29": // Tarjeta de servicios
          return { pattern: /^(\d{10,11}|\d{15,16}|\d{18}|[A-Z0-9_]{10,50})$/ };
        case "03": // Transferencia electrónica de fondos
          return { pattern: /^(\d{10}|\d{18})$/ };
        default:
          return "min:10|max:50";
      }
    },
    numOperacionRules: () => "max:100",
    nomBancoRules: () => "max:300"
  },
  
  mutations: {
    updateField,
    formasPagoLoaded(state) {
      state.formasPagoLoaded = true;
    },
    seriesLoaded(state) {
      state.seriesLoaded = true;
    },
    resetComponentsLoaded(state) {
      state.formasPagoLoaded = false;
      state.seriesLoaded = false;
    },
    setDialog(state, value) {
      state.dialog = value;
    },
    setPaymentDateMenu(state, value) {
      state.paymentDateMenu = value;
    },
    updatePaymentDateTime(state) {
      if (state.paymentDate) {
        const date = moment(state.paymentDate).format("YYYY-MM-DD");
        const time = moment().format("HH:mm:ss");
        state.formData.payment_date = `${date} ${time}`;
      }
    },
    resetForm(state) {
      const s = initialState();
      state.formData = s.formData;
      state.paymentDate = null;
      state.paymentTime = null;
      state.showOptionalFields = false;
    },
    incrementSerieFolio(state, folioCreated) {

        state.serie.folio = folioCreated + 1;
    },
    resetState(state) {
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    }
  },
  
  actions: {
    async timbrarComplementoPago({ state }) {
      try {
        const complementoPagoData = { ...state.formData };
        complementoPagoData.payment_amount = helper.currencyToNumber(complementoPagoData.payment_amount);
        complementoPagoData.serie_id = state.serie.id;

        const response = await apiMunzen.post("/facturacion/V40/complemento-pago", complementoPagoData);
        
        return response.data.data;
      } catch (error) {
        throw error;
      } 
    }
  }
};